import React, { useState, useEffect } from "react"
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/OurStoryPageNew/HeroSection/HeroSection"
import OurStory from "../components/OurStoryPageNew/OurStory/OurStory"
import ExecutiveTeam from "../components/OurStoryPageNew/ExecutiveTeam/ExecutiveTeam"
import OneTeamThreeContinents from "../components/OurStoryPageNew/OneTeamThreeContinents/OneTeamThreeContinents"
import SEO from "../components/Global/SEO/seo"
import { useViewportScroll } from "framer-motion"

const OurStoryPage = () => {

  const { scrollYProgress } = useViewportScroll();

  return(
    <Layout
      headerAbsolute
      headerTransparent
      headerMobile={'stay-absolute toggler-green ghostWhite'}
      whiteToggler
      logoDark={true}
    >
      <SEO page="about-us" />
      <HeroSection />
      <ExecutiveTeam />
      <OneTeamThreeContinents />
      <OurStory 
        scrollYProgress={scrollYProgress}
      />
    </Layout>
)}

export default OurStoryPage
